import React from 'react';
import './Welcome.css';

function Welcome() {
    return (
        <div className="container text-center overflow-hidden min-vh-100">
            <div className="row my-5">
                <div className="col p-3">
                    <p className="h1">Welcome to credentials.show!</p>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-sm">
                    <p className="h2">Login as provider</p>
                    <div>Issue new credentials or manage already issued</div>
                </div>
                <div className="col-sm">
                    <p className="h2">Login as user</p>
                    <div>View or manage your credentials</div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
